<template>
  <div class="list">
    <list-search @onSearch="search" @clearSearch="search" :childData="childData"></list-search>
    <div class="contractMenu">
        <list-menu @buttonClick="buttonClick" :menuData="menudata"></list-menu>
        <el-button @click="handlePreview " class="derivebtn" type="text" >+创建FA</el-button>
        <el-button @click="exportlist " class="rightMenu" type="text" >导出</el-button>
      </div>
    <global-table :tableField="tableField" @tableAction="tableAction" :tableData="tableData" :paginationData="paginationData" @pageChange="pageChange"
    ></global-table>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { SearchList, SearchData, tableField, tableData, menudata } from './js/setting.js'
import { request, exportForm } from '@/assets/js/http.js'
import dayjs from 'dayjs'
export default {
  components: Component.components,
  name: 'ExceptionManage',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      tableField: tableField,
      tableData: tableData,
      paginationData: {},
      pageNum: 1,
      pageSize: pageSize,
      menudata: menudata,
      childData: { searchList: SearchList, searchData: SearchData }
    }
  },
  created () {
    this.qualityList()
  },
  methods: {
    // 点击-创建8D报告按钮或者创建FA按钮
    buttonClick: function (data) {
      this.$router.push({ params: { viewName: 'AddExceptionManage' } })
    },
    handlePreview () {
      this.$router.push({ params: { viewName: 'AddExceptionFA' } })
    },
    // 获取质量协同-采购-质量通知单数据列表
    qualityList () {
      var searchdata = { ...this.childData.searchData }
      searchdata.pageNum = this.pageNum
      searchdata.pageSize = this.pageSize
      request('/api/quality/8dfa/list', 'get', searchdata).then((response) => {
        if (response.code === '200') {
          this.tableData = response.data.records
          if (response.data.total !== this.paginationData.total) {
            this.paginationData = { pageSize: this.pageSize, total: response.data.total }
          }
        }
      })
    },
    // 点击操作里的按钮进行操作
    tableAction: function (index, data, clickBtn) {
      if (clickBtn.operationButton === 'see') {
        this.$router.push({ params: { viewName: 'ExceptionManageDetail' }, query: { id: data.id } })
      } else {
        this.$router.push({ params: { viewName: 'AddClaimForm' }, query: { objData: data.id } })
      }
    },
    dateFormate: function (data) {
      return dayjs(data).format('YYYY-MM-DD')
    },
    search: function (data) {
      data.pageNum = this.pageNum
      data.pageSize = this.pageSize
      this.searchdata = { ...data }
      delete this.searchdata.createDate
      if (data.createDate) {
        this.searchdata.endTime = this.dateFormate(data.createDate[1])
        this.searchdata.startTime = this.dateFormate(data.createDate[0])
      }
      request('/api/quality/8dfa/list', 'get', this.searchdata).then((response) => {
        if (response.code === '200') {
          this.tableData = response.data.records
          if (response.data.total !== this.paginationData.total) {
            this.paginationData = { pageSize: this.pageSize, total: response.data.total }
          }
        }
      })
    },
    // 分页
    pageChange (data) {
      this.pageNum = data
      this.qualityList()
    },
    exportlist () {
      exportForm('/api/quality/8dfa/export', 'get').then((res) => {
        const url = window.URL.createObjectURL(res.data)
        const eleLink = document.createElement('a')
        eleLink.href = url
        eleLink.target = '_blank'
        eleLink.click()
        window.URL.revokeObjectURL(url)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.contractMenu{
 position: relative;
 .derivebtn{
  position: absolute;
  left: 170px;
  top: 8px;
  font-size: 16px;
 }
}
.rightMenu{
    position: absolute;
    right: 30px;
    top: 8px;
    font-size: 16px;
}
</style>
